<template>
 <sdDrawer
  ref="areaAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Gebied aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateArea">
    <a-row :gutter="16">
     <a-col :span="24">
      <a-form-item label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :span="12">
      <a-form-item label="Postcodegebied vanaf" name="postalCodeFrom">
       <a-input-number v-model:value="form.postalCodeFrom" :max="9999" :min="1000" class="w-100" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :span="12">
      <a-form-item label="Postcodegebied tot" name="postalCodeTill">
       <a-input-number v-model:value="form.postalCodeTill" :max="9999" :min="1000" class="w-100" placeholder=""/>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
    >
     <a-button :disabled="loadingCreateArea" :loading="loadingCreateArea" class="btn-signin" html-type="submit"
               size="large"
               type="primary"> Opslaan
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import {countryOptions} from "../../utility/enums";

const LeadAreaAdd = defineComponent({
 name: 'LeadAreaAdd',
 components: {
  FormValidationWrap,
 },
 setup() {
  const areaAddRef = ref()
  const formRef = ref();
  const {state, dispatch} = useStore();
  const loadingCreateArea = computed(() => state.lead.loadingCreateArea);
  const form = reactive({
   name: '',
   postalCodeTill: '',
   postalCodeFrom: '',
  });
  const rules = {
   name: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   postalCodeTill: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   postalCodeFrom: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const closeDrawer = () => {
   areaAddRef.value.onClose();
   formRef.value.resetFields();
   dispatch('getAreas')
  }
  const CreateArea = async () => {
   await dispatch('createArea', {value: form, close: closeDrawer});
   await dispatch('getAreas', {page: 1, pageSize: state.lead.pageSizeArea});
  };


  return {
   form,
   rules,
   CreateArea,
   countryOptions,
   loadingCreateArea,
   areaAddRef,
   formRef,
  };
 },
});

export default LeadAreaAdd;
</script>
