<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="leadTableColumns"
    :dataSource="leadTableData"
    :loading="loadingLeads"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper, TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {getLeadStatus} from "../../../utility/utility";

const leadTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Naam',
  dataIndex: 'relationName',
  key: 'relationName',
 },
 {
  title: 'Gebied',
  dataIndex: 'area',
  key: 'area',
 },
 {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
 },
 {
  title: 'Datum & Tijd',
  dataIndex: 'date',
  key: 'date',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const LeadsListTable = defineComponent({
 name: 'LeadsListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  // const router = useRouter();
  const {state, dispatch} = useStore();
  // const {matched} = useRoute();
  // const {path} = matched[1];
  const leads = computed(() => state.lead.leads);
  const loadingLeads = computed(() => state.lead.loadingLeads);
  const currentPage = computed(() => state.lead.currentPage);
  const count = computed(() => state.lead.count);
  // const {can} = useAbility();
  // const handleDelete = async (id) => {
  //  await dispatch('deleteSender', id);
  //  await dispatch('getSenders', {page: state.lead.currentPage, pageSize: state.lead.pageSize});
  // };
  // const handleEdit = (id) => {
  //  router.push(`${path}/${id}`);
  // };
  const leadTableData = computed(() =>
   leads.value.map((lead) => {
    const {id, relationName, leadAreaTimeBlock, date, status, leadArea} = lead;

    return {
     key: id,
     id: id,
     area: <span>{leadArea.name} <br/><small>{leadArea.postalCodes}</small></span>,
     relationName: <span>{relationName}</span>,
     status: <span>{getLeadStatus(status)}</span>,
     date: <span>{leadAreaTimeBlock?.timeFrom}-{leadAreaTimeBlock?.timeTill} {moment(date).format('DD-MM-YYYY')}</span>,
     // action: (
     //  <div className="table-actions">
     //   {can('view', 'sender') ? (
     //    <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
     //     <sdFeatherIcons type="edit" size="16"/>
     //    </sdButton>
     //   ) : ('')}
     //   {can('delete', 'sender') ? (
     //    <a-popconfirm
     //     title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
     //     ok-text="Ja"
     //     cancel-text="Nee"
     //     placement="leftTop"
     //     onConfirm={() => handleDelete(id)}
     //    >
     //     <sdButton className="btn-icon" type="default" to="#" shape="circle">
     //      <sdFeatherIcons type="trash-2" size="16"/>
     //     </sdButton>
     //    </a-popconfirm>
     //   ) : ('')}
     //  </div>
     // ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled sender', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageLeads', event.current);
   await dispatch('getLeads', {page: event.current, pageSize: state.sender.pageSize});
  }

  return {leadTableColumns, leadTableData, rowSelection, loadingLeads, paginationChange, count, currentPage};
 },
});

export default LeadsListTable;
</script>
