<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="relationTableColumns"
    :dataSource="relationTableData"
    :loading="loadingRelations"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";

const relationTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
 },
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Adres',
  dataIndex: 'fullAddress',
  key: 'fullAddress',
 },
 {
  title: 'Aangemaakt op',
  dataIndex: 'createTimestamp',
  key: 'createTimestamp',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const RelationListTable = defineComponent({
 name: 'RelationListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const {state, dispatch} = useStore();
  const relations = computed(() => state.relation.relations);
  const loadingRelations = computed(() => state.relation.loadingRelations);
  const currentPage = computed(() => state.relation.currentPage);
  const count = computed(() => state.relation.count);
  const router = useRouter();
  const {matched} = useRoute();
  const {path} = matched[1];
  const handleDelete = async (id) => {
   await dispatch('deleteRelation', id);
   await dispatch('getRelations', {page: state.relation.currentPage, pageSize: state.relation.pageSize});
  };
  const handleEdit = (id) => {
   router.push(`${path}/${id}`);
  };
  const relationTableData = computed(() =>
   relations.value.map((relation) => {
    const {id, fullName, fullAddress, createTimestamp, type} = relation;

    var typeLocalized;
    if (type === 'CONSUMER') {
     typeLocalized = "Consument";
    } else {
     typeLocalized = "Zakelijk";
    }

    return {
     key: id,
     id: id,
     type: (
      <div class="user-info">
       <figcaption>
        <sdHeading class="user-name" as="h6">
         {typeLocalized}
        </sdHeading>
       </figcaption>
      </div>
     ),
     name: (
      <div class="user-info">
       <figure>
        {type && type === 'CONSUMER' ? (
         <img style={{height: '25px'}} src={require('@/static/img/person-solid.svg')} alt=""/>
        ) : (
         <img style={{height: '25px'}} src={require('@/static/img/building-solid.svg')} alt=""/>
        )}
       </figure>
       <figcaption>
        <sdHeading class="user-name" as="h6">
         {fullName}
        </sdHeading>
       </figcaption>
      </div>
     ),
     fullAddress: <span>{fullAddress}</span>,
     createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
     action: (
      <div class="table-actions">
       <sdButton onClick={() => handleEdit(id)} class="btn-icon" to="#" type="default" shape="circle">
        <sdFeatherIcons type="edit" size="16"/>
       </sdButton>
       <a-popconfirm
        title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
        ok-text="Ja"
        cancel-text="Nee"
        placement="leftTop"
        onConfirm={() => handleDelete(id)}
       >
        <sdButton class="btn-icon" type="default" to="#" shape="circle">
         <sdFeatherIcons type="trash-2" size="16"/>
        </sdButton>
       </a-popconfirm>
      </div>
     ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled relation', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageRelation', event.current);
   await dispatch('getRelations', {page: event.current, pageSize: state.relation.pageSize});
  }

  return {
   relationTableColumns,
   relationTableData,
   rowSelection,
   loadingRelations,
   paginationChange,
   currentPage,
   count
  };
 },
});

export default RelationListTable;
</script>
