<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="leadTableColumns"
    :dataSource="leadTableData"
    :loading="loadingAreas"
    :pagination="{
          defaultPageSize: 10,
          total: countArea,
          current: currentPageArea,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${countArea} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper, TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";

const leadTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Postcodegebied vanaf',
  dataIndex: 'postalCodeFrom',
  key: 'postalCodeFrom',
 },
 {
  title: 'Postcodegebied tot',
  dataIndex: 'postalCodeTill',
  key: 'postalCodeTill',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const LeadAreasListTable = defineComponent({
 name: 'LeadAreasListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const router = useRouter();
  const {state, dispatch} = useStore();
  const {matched} = useRoute();
  const {path} = matched[1];
  const areas = computed(() => state.lead.areas);
  const loadingAreas = computed(() => state.lead.loadingAreas);
  const currentPageArea = computed(() => state.lead.currentPageArea);
  const countArea = computed(() => state.lead.countArea);
  // const {can} = useAbility();
  // const handleDelete = async (id) => {
  //  await dispatch('deleteSender', id);
  //  await dispatch('getSenders', {page: state.lead.currentPage, pageSize: state.lead.pageSize});
  // };
  // const handleEdit = (id) => {
  //  router.push(`${path}/${id}`);
  // };

  // const leadTableData = computed(() =>
  //  areas.value.map((area) => {
  //   const {id, name, postalCodes} = area;
  //
  //   return {
  //    key: id,
  //    id: <span>{id}<span>,
  //    name: <span>{name}<span>,
  //    postalCodes: <span>{postalCodes}</span>,
  //   };
  //
  //  }),
  // );

  // };

  const handleEdit = (id) => {
   router.push(`${path}/${id}`);
  };
  const leadTableData = computed(() =>
   areas.value.map((area) => {
    const {id, name, postalCodeFrom, postalCodeTill} = area;

    return {
     key: id,
     id: id,
     name: name,
     postalCodeFrom: postalCodeFrom,
     postalCodeTill: postalCodeTill,
     action: (
      <div className="table-actions">
       <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
        <sdFeatherIcons type="edit" size="16"/>
       </sdButton>
      </div>
     ),
    };
   }),
  );


  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled sender', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageAreas', event.current);
   await dispatch('getAreas', {page: event.current, pageSize: state.leads.pageSizeArea});
  }

  return {leadTableColumns, leadTableData, rowSelection, loadingAreas, paginationChange, countArea, currentPageArea};
 },
});

export default LeadAreasListTable;
</script>
