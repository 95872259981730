<template>
  <CardToolbox>
    <sdPageHeader title="Relaties">
      <template #subTitle>
        <span class="title-counter">{{ count }} relatie{{ count > 0 && count < 2 ? '' : 's' }}</span>
        <AutoCompleteStyled style="width:100%" placeholder="Zoeken op naam...">
          <a-input type="input" v-model:value="search">
            <template #suffix>
              <sdFeatherIcons type="search"/>
            </template>
          </a-input>
        </AutoCompleteStyled>
      </template>
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="1">
          <router-link to="/relation/add">
            <sdFeatherIcons type="plus" size="14"/>
            Toevoegen
          </router-link>
        </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <RelationTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import RelationTable from './component/RelationTable';
import {computed, ref, defineComponent, onMounted, watch} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox, AutoCompleteStyled} from '../styled';
import locale from "@/static/locale/nl.json";

const RelationList = defineComponent({
  name: 'RelationList',
  components: {Main, CardToolbox, RelationTable, AutoCompleteStyled},
  data() {
    return {
      showEditDrawer: false,
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.relation.count);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);
    const search = ref(null);
    let debounce = null;

    watch(search, (query) => {
      clearTimeout(debounce)
      debounce = setTimeout(async () => {
        const searchValues = {
          q: query,
          pageSize: state.organization.pageSize,
          page: 1,
        };
        await dispatch('setCurrentPageRelation', 1);
        await dispatch('getRelations', searchValues);
      }, 600);
    });

    onMounted(() => {
      dispatch('getRelations', {page: state.relation.currentPage, pageSize: state.relation.pageSize});
    });

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      count,
      locale,
      search,
    };
  },
});

export default RelationList;
</script>
