<template>
 <CardToolbox>
  <sdPageHeader title="Gebieden">
   <template #subTitle>
    <span class="title-counter">{{ count }} gebied{{ count > 0 && count < 2 ? '' : 'en' }}</span>
    <AutoCompleteStyled placeholder="Zoeken op naam..." style="width:100%">
     <a-input v-model:value="search" type="input">
      <template #suffix>
       <sdFeatherIcons type="search"/>
      </template>
     </a-input>
    </AutoCompleteStyled>
   </template>
   <template #buttons>
    <LeadAreaAdd v-if="$can('create', 'leadArea')"/>
   </template>
  </sdPageHeader>
 </CardToolbox>

 <Main>
  <a-row :gutter="15">
   <a-col :md="24">
    <LeadAreasTable/>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {AutoCompleteStyled, CardToolbox, Main} from '../styled';
import locale from "@/static/locale/nl.json";
import LeadAreasTable from "./component/LeadAreasTable.vue";
import LeadAreaAdd from "./LeadAreaAdd.vue";

const LeadAreaList = defineComponent({
 name: 'LeadAreaList',
 components: {LeadAreaAdd, Main, CardToolbox, LeadAreasTable, AutoCompleteStyled},
 setup() {
  const {state, dispatch} = useStore();
  const searchData = computed(() => state.headerSearchData);
  const count = computed(() => state.lead.countArea);
  const selectedRowKeys = ref(0);
  const selectedRows = ref(0);
  const search = ref(null);
  let debounce = null;

  watch(search, (query) => {
   clearTimeout(debounce)
   debounce = setTimeout(async () => {
    const searchValues = {
     q: query,
     pageSize: state.lead.pageSizeArea,
     page: 1,
    };
    await dispatch('setCurrentPageAreas', 1);
    await dispatch('getAreas', searchValues);
   }, 600);
  });

  onMounted(() => {
   window.scrollTo(0, 0);
   dispatch('getAreas', {page: state.lead.currentPageArea, pageSize: state.lead.pageSizeArea});
  });

  return {
   searchData,
   selectedRowKeys,
   selectedRows,
   count,
   locale,
   search,
  };
 },
});

export default LeadAreaList;
</script>
